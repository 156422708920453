import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import { emergencyInfoContact } from "../actions";
import Sidebar from "./Sidebar";
import ContainerMap from "./CointainerMap";
import Logo from "../img/iconsGradient.png";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

function AppView(props) {
  const classes = useStyles();
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (!item) {
      var URLactual = window.location.search;

      let params = new URLSearchParams(URLactual);
      let emergency_id = params.get("emergency_id");
      let contact_identificator = params.get("contact_identificator");

      setItem(emergency_id);
      props.emergencyInfoContact(emergency_id, contact_identificator);
    }
  }, [item, props]);

  const { error, info } = props;

  if (error)
    return (
      <>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img src={Logo} width="500" height="500" alt="logo" />

          <h1 style={{ color: "#fe0014" }}>Si Datos</h1>
        </div>
      </>
    );

  if (info === null)
    return (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <img src={Logo} width="500" height="500" alt="logo" />
      </div>
    );
  else
    return (
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={9}>
            <Paper className={classes.paper}>
              <ContainerMap />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              <Sidebar />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
}

/*class AppView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { matches: window.matchMedia("(min-width: 768px)").matches };
  }

  componentDidMount() {
    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);

    var URLactual = window.location.search;

    let params = new URLSearchParams(URLactual);
    let emergency_id = params.get("emergency_id");
    let contact_identificator = params.get("contact_identificator");

    this.props.emergencyInfoContact(emergency_id, contact_identificator);
  }
  render() {
    const { error, info } = this.props;

    const classes = useStyles();

    if (error)
      return (
        <>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img src={Logo} width="500" height="500" alt="logo" />

            <h1 style={{ color: "#fe0014" }}>Si Datos</h1>
          </div>
        </>
      );

    if (info === null)
      return (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
          }}
        >
          <img src={Logo} width="500" height="500" alt="logo" />
        </div>
      );
    else
      return (
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>xs=12</Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>xs=6</Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>xs=6</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>xs=3</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>xs=3</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>xs=3</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>xs=3</Paper>
            </Grid>
          </Grid>
        </div>
      );
  }
}*/

const mapStateToProps = state => {
  return {
    info: state.reducer.info,
    error: state.reducer.error,
    isFeching: state.reducer.isFeching
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ emergencyInfoContact }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppView);
