import { LOADING_USER, INFO_EMERGENCY_SUCCESS, FAILURE } from "../actions";

const initialState = {
  data: [],
  isFeching: false,
  error: false,
  info: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_USER:
      return {
        ...state,
        isFeching: true
      };

    case INFO_EMERGENCY_SUCCESS:
      return {
        ...state,
        info: action.data,
        isFeching: false,
        error: false
      };

    case FAILURE:
      return {
        ...state,
        error: true,
        info: null,
        isFeching: false
      };

    default:
      return state;
  }
}
