const api = "https://novemapp.com:8080/";

export const fetchEmergency = url =>
  fetch(`${api}${url}`, {
    method: "GET"
  })
    .then(res => res.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      console.log(`The error is: ${error.message} in fetchEmergency`);
    });
