import * as apiNovemApp from "../api";

export const FAILURE = "FAILURE";
export const INFO_EMERGENCY_SUCCESS = "INFO_EMERGENCY_SUCCESS";
export const LOADING_USER = "LOADING_USER";

export const failure = data => {
  return { type: FAILURE, data };
};

export const infoEmergency = data => {
  return { type: INFO_EMERGENCY_SUCCESS, data };
};

export const loading = data => {
  return { type: LOADING_USER, data };
};

export const emergencyInfoContact = (emergency_id, contact_identificator) => {
var url = `emergency/info-guest?emergency_id=${emergency_id}&contact_identificator=${contact_identificator}`;

  const request = apiNovemApp.fetchEmergency(url);

  return dispatch => {
    request.then(result => {
      console.log(result);
      try {
        if (result.status === 200) {
          dispatch(infoEmergency(result.data));
        } else {
        //  dispatch(failure(result.data))
        }
      } catch (e) {
        console.log("Error in emergencyInfoContact");
      }
    });
  };
};
